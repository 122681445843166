<template>
  <div class="epitelium">
    <div class="content">
      <div class="epitelium__caption">Хронология событий</div>
      <div class="epitelium__subcaption">
        В деле «Тяжелая бронхиальная астма»
      </div>
      <div class="epitelium__description">
        Фокус внимания на эпителиальных цитокинах, стоящих на вершине
        воспалительного каскада, таких как TSLP, IL-33 и IL-25, позволяет нам по
        новому взглянуть на тяжелую бронхиальную астму<sup
          style="color: inherit"
          >1-3</sup
        >.
      </div>
    </div>
    <div class="epitelium__rotation">
      <div class="content">
        <div class="epitelium__game">
          <div class="epitelium-game__caption">
            <span class="d-none d-lg-block">Проверьте ваши знания</span>
            <span class="d-lg-none"
              >Полная сцена в случае тяжелого воспаления астмы</span
            >
          </div>
          <div class="epitelium-game__description">
            <span class="d-none d-lg-block"
              >Перенесите иммунные клетки в воспалительный каскад.</span
            >
            <span class="d-lg-none"
              >Сосредоточение внимания на эпителиальных цитокинах на вершине
              воспалительного каскада, таких как TSLP, IL-33 и IL-25, предлагает
              новый взгляд на тяжелую астму.
              <sup style="color: inherit">1-3</sup></span
            >
          </div>
          <div
            @click="startGame"
            class="button d-none d-lg-flex epitelium-game__button"
          >
            {{ gameFinished ? "Начать заново" : "Собрать полную картину" }}
          </div>
          <EpiteliumGameComp
            class="d-none d-lg-block"
            :gameStarted="gameStarted"
            @isDone="finishGame"
            :key="gameKey"
          />
          <div class="epitelium__video-container d-lg-none">
            <video
              class="epitelium__video"
              loop
              muted
              autoplay
              playsinline
              preload="metadata"
              src="/content/epitelium/back-min.mp4"
            ></video>
            <img
              class="epitelium__game-img"
              :src="
                require('@/assets/img/epitelium/game/default-back-adaptive.jpg')
              "
            />
          </div>
          <div class="epitelium-game__bottom">
            <div class="epitelium-game__bottom-title">
              Утолщение гладкой мускулатуры дыхательных путей, гиперреактивность
              и потенциальное ремоделирование
              <sup style="color: inherit">10,11,16,17</sup>
            </div>
            <div class="epitelium-game__bottom-description">
              Высвобождение эпителиальных цитокинов способствует <br>патологическому воспалению, которое может вызвать усиление симптомов и обострение астмы<sup style="color: inherit">1,4,5,18</sup>.
            </div>
            <div class="epitelium-game__bottom-tip">
              * Механизмы, лежащие в основе неэозинофильного воспаления, требуют
              дальнейшего изучения
            </div>
          </div>
        </div>
      </div>
      <div class="epitelium__gray-block">
        <div class="content">
          <div class="epitelium__first-block first-block">
            <div class="first-block__image-container">
              <img
                class="first-block__image"
                :src="require('@/assets/img/epitelium/game/ipad-quiz.jpg')"
              />
            </div>
            <div class="first-block__text-container">
              <div class="first-block__caption">
                Под силу ли Вам перехитрить эпителиальные цитокины?
              </div>
              <div class="first-block__description">
                Ответьте на следующие вопросы, чтобы узнать, как много вы знаете
                о роли эпителиальных цитокинов в воспалении при тяжелой
                бронхиальной астме.
              </div>
              <router-link
                :to="{ name: 'EpiteliumQuiz', query: $route.query }"
                class="first-block__button button"
                >Пройти тест</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="epitelium__main-video" id="link2">
        <div class="epitelium__main-video-caption">
          Посмотреть видео о механизме развития заболевания
        </div>
        <video
          src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/epitelium/main-video.mp4"
          :poster="require('@/assets/img/epitelium/video-poster.jpg')"
          controls="true"
          class="epitelium__main-video-video"
        />
      </div>
      <InfoDrop :arrowColor="'#fff'" :item="shortList" class="mb-12" />
      <div class="epitelium__crime-list" id="link1">
        <div class="epitelium__crime-list-caption">
          Изучить список подозреваемых
        </div>
        <img
          class="epitelium__img"
          :src="require('@/assets/img/epitelium/4.jpg')"
        />
        <div class="epitelium__crime-list-description">
          <span
            >Узнайте, каким образом ключевые эпителиальные цитокины (TSLP, IL-33
            и IL-25) <br />участвуют в развитии воспаления при тяжелой
            астме<sup style="color: inherit">1-3,5</sup>.</span
          >
          <router-link
            :to="{ name: 'Epitelium', query: $route.query }"
            class="
              button button_blue
              d-inline-flex
              px-4
              py-3
              epitelium__crime-list-button
            "
          >
            Узнать больше
          </router-link>
        </div>
      </div>
      <InfoDrop :arrowColor="'#fff'" :item="sources" />
    </div>
  </div>
</template>

<script>
import EpiteliumGameComp from "../../components/pageComponents/EpiteliumGameComp.vue";
import InfoDrop from "@/components/pageComponents/InfoDrop.vue";
export default {
  components: { EpiteliumGameComp, InfoDrop },
  name: "EpiteliumGame",
  data: () => ({
    gameStarted: true,
    gameFinished: false,
    gameKey: 0,
  }),
  computed: {
    shortList() {
      return {
        title: "<span style='color: #fff'>Список сокращений</span>",
        content: `
        <span style='color: #fff'>
        TSLP – тимусный стромальный лимфопоэтин; 
        <br>
        <br>
        Il – интерлейкин; 
        <br>
        <br>
        Ig – иммуноглобуллин; 
        <br>
        <br>
        ТН2 - Т2 – хелперы.
        </span>
        `,
      };
    },
    sources() {
      return {
        title: "<span style='color: #fff'>Источники</span>",
        content: `
        <ol style="color: #fff">
        <li>Corren J. J Allergy Clin Immunol Pract. 2019;7(5):1394-1403. </li>
        <li>Lambrecht BN, Hammad H, Fahy JV. Immunity. 2019;50(4):975-991. </li>
        <li>Lambrecht BN, Hammad H. Nat Immunol. 2015;16(1):45-56. </li>
        <li>Ying S, O'Connor B, Ratoff J, et al. J Immunol. 2005;174(12):8183-8190. </li>
        <li>Liang Y, Yu B, Chen J, et al. J Allergy Clin Immunol. 2019;144(4):1025-1035. </li>
        <li>Li Y, Wang W, Lv Z, et al. J Immunol. 2018;200(7):2253-2262. </li>
        <li>Tanaka J, Watanabe N, Kido M, et al. Clin Exp Allergy. 2009;39(1):89-100. </li>
        <li>Chesné J, Braza F, Mahay G, et al. Am J Respir Crit Care Med. 2014;190(10):1094-1101. </li>
        <li>Menzies-Gow A, Wechsler ME, Brightling CE. Respir Res. 2020;21(1):268:1-7. </li>
        <li>Sy CB, Siracusa MC. Front Physiol. 2016;7(214):1-7. </li>
        <li> Bartemes KR, Kita H. Clin Immunol. 2012;(3):222-235. </li>
        <li>1Redhu NS, Gounni AS. Clin Exp Allergy. 2012;42(7):994-1005. </li>
        <li>Roan F, et al. J Clin Invest. 2019;12(4):1441-1451. </li>
        <li>Brusselle G, Bracke K. Ann Am Thorac Soc. 2014;11:S322-S328. </li>
        <li>Pelaia G, et al. Nat Rev Drug Discov. 2012;11:958-997. </li>
        <li>Pasha MA, et al. Allergy Asthma Proc. 2019;40(3):138-145. </li>
        <li>Brusselle G, et al. Nat Med. 2013;19:977-979. </li>
        <li>Gauvreau GM, et al. Expert Opinion on Therapeutic Targets. 2020;24(8):777-792. </li>
        <li>Wu J et al. Cell Biochem Funct. 2013;31:496–503. </li>
        <li>Divekar R, Kita H. Curr Opin Allergy Clin Immunol. 2015 Feb;15(1):98-103. </li>
        <li>Hams E, et al. Proc Natl Acad Sci U S A. 2014 Jan 7;111(1):367-372. </li>
        <li>An G, et al. Immunology. 2018 Feb 18;154(4):637–650. </li>
        <li>An G, et al. Respirology. 2020 Jun;25(6):603-612. </li>
        <li>Kaur D, et al. Chest. 2012;142:76-85. </li>
        <li>Cao L et al. Exp Lung Res. 2018;44:288–301.</li> 
        <li>Shikotra A, Choy DF, Ohri SM, et al. J Allergy Clin Immunol . 129(1):104-111.e9.</li>
        </ol>
        `,
      };
    },
  },
  methods: {
    startGame() {
      if (!this.gameFinished) {
        this.gameStarted = !this.gameStarted;
      } else {
        this.gameFinished = false;
        this.gameKey++;
      }
    },
    finishGame() {
      this.gameFinished = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.epitelium {
  padding-top: 48px;

  &__caption {
    margin-bottom: 8px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 48px;
    color: #fff;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 32px;
      padding: 0 15%;
    }
  }

  &__subcaption {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 26px;
    color: #fff;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  &__video {
    width: 100%;
    height: auto;

    &-caption {
      margin-top: 32px;
      margin-bottom: 24px;
      color: #fafce9;
      text-align: center;
      /* Desktop/Heading/H2 */
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px; /* 126.087% */
    }

    &-description {
      margin-bottom: 24px;
      color: #d2d2d2;
      text-align: center;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    &-tip {
      color: #d2d2d2;
      text-align: center;
      /* Desktop/Label/S */
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  &__game-img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
  }

  &__img {
    width: 100%;
    height: auto;

    &-overflow {
      @media screen and (max-width: 1220px) {
        overflow-x: auto;
        margin: 0 -76px;
        padding-left: 76px;
      }

      @media screen and (max-width: 767px) {
        margin: 0 -16px;
        padding-left: 16px;
      }

      & img {
        @media screen and (max-width: 1220px) {
          width: 120vw;
        }
        @media screen and (max-width: 767px) {
          width: 200vw;
        }
      }
    }
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #fff;
    text-align: center;
  }

  &__game {
    margin-top: 96px;

    @media screen and (max-width: 1220px) {
      margin-top: 80px;
    }
  }

  &__rotation {
    @media screen and (max-width: 1220px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &-game {
    &__caption {
      margin-bottom: 16px;
      color: #fff;
      text-align: center;
      /* Desktop/Label/Extra */
      font-family: "Roboto Slab", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 114.286% */

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &__description {
      margin-bottom: 32px;
      color: #d2d2d2;
      text-align: center;
      /* Desktop/Body/Regular/M */
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
    }

    &__button {
      margin: 0 auto 32px;
      width: fit-content;
      background-color: #8dc53e;
      padding: 10px 18px;
      color: #fff;

      &:hover {
        background-color: #79aa35;
      }
    }

    &__bottom {
      margin-top: 32px;
      &-title {
        margin-bottom: 24px;
        color: #fafce9;
        text-align: center;
        /* Desktop/Heading/H2 */
        font-family: "Roboto Slab", sans-serif;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px; /* 126.087% */

        @media screen and (max-width: 767px) {
          font-size: 21px;
          line-height: 26px;
        }
      }

      &-description {
        margin-bottom: 24px;
        color: #d2d2d2;
        text-align: center;
        /* Desktop/Body/Regular/M */
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
      }

      &-tip {
        color: #d2d2d2;
        text-align: center;
        /* Desktop/Label/S */
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
      }
    }
  }
  &__main-video {
    margin: 80px 0;

    @media screen and (max-width: 767px) {
      margin: 40px 0;
    }

    &-caption {
      padding: 18px 32px;
      background-color: #8dc53e;
      color: #fafce9;
      /* Desktop/Label/L */
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
    }

    &-video {
      width: 100%;
      height: auto;
    }
  }

  &__gray-block {
    background-color: #111419;
    padding: 40px 0 30px;
    margin-top: 40px;
  }

  &__crime-list {
    margin-top: 80px;
    margin-bottom: 80px;
    & .epitelium__img {
      max-width: 50%;
      margin: 0 auto;

      @media screen and (max-width: 1220px) {
        max-width: none;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 40px;
    }
    &-caption {
      text-align: center;
      margin-bottom: 100px;
      color: #fafce9;
      font-family: "Roboto Slab", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 114.286% */

      @media screen and (max-width: 1220px) {
        font-weight: 400;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-description {
      margin-top: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #d2d2d2;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */

      @media screen and (max-width: 1220px) {
        align-items: flex-start;

        & br {
          display: none;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-button {
      margin-left: auto;
      @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-top: 32px;
        width: 100%;
      }
    }
  }
}

.first-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 1220px) {
    flex-direction: column;
  }

  &__image-container {
    width: 584px;
    height: auto;
    margin-right: 74px;
    flex-shrink: 0;

    @media screen and (max-width: 1220px) {
      margin-bottom: 32px;
      margin-right: 0;
      width: 100%;
    }
  }

  &_reverse {
    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
    .first-block {
      &__image-container {
        margin-right: 0;
        margin-left: 74px;

        @media screen and (max-width: 1220px) {
          margin-top: 32px;
          margin-left: 0;
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  &__text-container {
    margin: auto 0;
  }

  &__caption {
    margin-bottom: 24px;
    color: #fafce9;
    /* Desktop/Label/Extra */
    font-family: "Roboto Slab", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 114.286% */

    @media screen and (max-width: 1220px) {
      font-weight: 400;
      font-size: 23px;
    }

    @media screen and (max-width: 767px) {
      font-size: 21px;
    }
  }

  &__description {
    margin-bottom: 32px;
    color: #d2d2d2;
    font-family: "Roboto Slab", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  &__button {
    width: fit-content;
    background-color: #8dc53e;
    padding: 10px 18px;
    color: #fff;

    @media screen and (max-width: 1220px) {
      margin: 0 auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &:hover {
      background-color: #79aa35;
    }
  }
}
</style>